import React from 'react';
import { createPortal } from 'react-dom';
import { injectIntl } from 'react-intl';
import { AsureCloudGreeting } from '@asuresoftware/asure.design-system';

function Greeting() {
  const target = document.getElementById('dashboard-greeting-ui');

  const greetingContent = <AsureCloudGreeting />;

  if (target) {
    return createPortal(greetingContent, target);
  }
  return null;
}

export default injectIntl(Greeting);
