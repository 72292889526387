import React from 'react';
import { createPortal } from 'react-dom';
import { AsureCloudWidgetWrapper } from '@asuresoftware/asure.design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch } from '@fortawesome/pro-duotone-svg-icons';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import './TimeClock.scss';

function TimeClock() {
  const target = document.getElementById('dashboard-time-clock-ui');

  const componentContent = (
    <AsureCloudWidgetWrapper className="time-clock d-flex flex-row justify-content-start align-items-center ">
      <FontAwesomeIcon icon={faStopwatch} className="stopwatch-icon" />
      <a
        href="#dashboard-quick-links-ui"
        className="ml-3 d-flex flex-row justify-content-center align-items-center align-content-center"
      >
        <h1 className="m-0 mr-2">Time Clock</h1>
        <FontAwesomeIcon
          icon={faExternalLink}
          size="sm"
          className="external-link-icon"
        />
      </a>
    </AsureCloudWidgetWrapper>
  );

  if (target) {
    return createPortal(componentContent, target);
  }

  return null;
}
export default TimeClock;
