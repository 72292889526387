import React from 'react';
import './QuickLinks.scss';
import { createPortal } from 'react-dom';
import { AsureCloudWidgetWrapper } from '@asuresoftware/asure.design-system';

function QuickLinks() {
  const target = document.getElementById('dashboard-quick-links-ui');
  const links = ['View paystubs', 'Direct deposit', 'Time off'];

  const componentContent = (
    <div className="portal">
      <AsureCloudWidgetWrapper header="Quick Links">
        <ul className="m-0 p-0">
          {links.map((link) => (
            <li key={link}>
              <a className="text-decoration-none" href="#dashboard-quick-links-ui">
                {link}
              </a>
            </li>
          ))}
        </ul>
      </AsureCloudWidgetWrapper>
    </div>
  );

  if (target) {
    return createPortal(componentContent, target);
  }

  return null;
}

export default QuickLinks;
