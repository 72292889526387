import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  QuickLinks,
  TestComponent,
  Greeting,
  TasksComponent,
  Announcements,
  TimeClock,
  MainBanner,
} from '.';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/testcomponent" component={TestComponent} />
        <Route path="/greeting" component={Greeting} />
        <Route path="/quicklinks" component={QuickLinks} />
        <Route path="/timeclock" component={TimeClock} />
        <Route path="/mainbanner" component={MainBanner} />
        <Route path="/tasks" component={TasksComponent} />
        <Route path="/announcements" component={Announcements} />
        <Route path="/">
          <Greeting />
          <TimeClock />
          <MainBanner />
          <QuickLinks />
          <TasksComponent />
          <Announcements />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
