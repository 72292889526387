import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { AsureCloudBanner } from '@asuresoftware/asure.design-system';

export const Documents = () => {
  const [documentCount, setDocumentCount] = useState('');
  const pluralNoun = `${documentCount > 1 ? 's' : ''}`;
  const pluralVerb = `${documentCount === 1 ? 's' : ''}`;
  const documentHeading = `Document${pluralNoun} to sign`;
  const documentMessage = `You have ${documentCount} document${pluralNoun} that need${pluralVerb} your signature`;
  const documentLinkDisplay = 'Go to documents';
  const documentLinkUrl = '/Secure/Employee/EmployeeDocumentList.aspx?menu=ESS';

  const getDocumentCount = () => {
    const windowVariable = get(window, 'asure.appSettings.esignature.employeePendingDocCount');
    console.log(windowVariable);
    setDocumentCount(windowVariable);
  };

  useEffect(() => {
    getDocumentCount();
  }, []);

  if (documentCount > 0) {
    return (
      <div className="portal">
        <AsureCloudBanner
          heading={documentHeading}
          message={documentMessage}
          linkUrl={documentLinkUrl}
          linkDisplay={documentLinkDisplay}
        />
      </div>
    );
  }

  return null;
};

export default Documents;
