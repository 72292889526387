import React from 'react';
import { createPortal } from 'react-dom';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckAlt } from '@fortawesome/pro-duotone-svg-icons/faMoneyCheckAlt';
import { AsureCloudIconBanner } from '@asuresoftware/asure.design-system';

function MainBanner() {
  const target = document.getElementById('dashboard-main-banner-ui');
  const Icon = <FontAwesomeIcon icon={faMoneyCheckAlt} />;
  const componentContent = (
    <div className="portal">
      <AsureCloudIconBanner
        icon={Icon}
        linkDisplay="Acknowledge"
        linkUrl="#"
        heading="You got paid Thursday, Apr 21!"
        message="Next payday on May 10"
      />
    </div>
  );
  if (target) {
    return createPortal(componentContent, target);
  }
  return null;
}
export default injectIntl(MainBanner);
