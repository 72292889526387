import React from 'react';
import { createPortal } from 'react-dom';
import { injectIntl } from 'react-intl';
import { AsureCloudBanner } from '@asuresoftware/asure.design-system';
import Documents from './Documents';

function Tasks() {
  const target = document.getElementById('dashboard-tasks-ui');
  const taskList = [
    {
      heading: 'Forklift Drivers License expiring',
      message: 'Your Forklift Drivers License will expire on Feb 7',
      linkUrl: '#',
      linkDisplay: 'Acknowledge',
    },
  ];
  const taskListComponents = taskList.map((task) => (
    <AsureCloudBanner
      key={`${task.linkUrl}_${task.linkDispay}`}
      heading={task.heading}
      message={task.message}
      linkUrl={task.linkUrl}
      linkDisplay={task.linkDisplay}
    />
  ));
  const componentContent = (
    <div className="portal">
      <h5 className="font-weight-bold">To do</h5>
      {taskListComponents}
      <Documents />
    </div>
  );

  if (target) {
    return createPortal(componentContent, target);
  }

  return null;
}

export default injectIntl(Tasks);
