import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { IntlProvider } from 'react-intl';

import { localeData } from './i18n/locale';
import App from './Containers/App';
import reportWebVitals from './reportWebVitals';

const app = (
  <IntlProvider locale={localeData.locale} messages={localeData.localeMessages}>
    <App />
  </IntlProvider>
);

ReactDOM.render(app, document.getElementById('asurecloud-react-app'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
