import React from 'react';
import { createPortal } from 'react-dom';
import { injectIntl } from 'react-intl';
import { AsureCloudAnnouncements } from '@asuresoftware/asure.design-system';
import './Announcements.scss';

function Announcements() {
  const target = document.getElementById('dashboard-announcements-ui');

  const dataList = [
    {
      postId: 1,
      postTitle: 'This is your HR portal',
      postDate: '01/01/2021',
      postDetail:
        "imply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      postId: 2,
      postTitle: 'This is your second HR portal',
      postDate: '01/01/2022',
      postDetail:
        "imply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1600s",
    },
  ];

  const AnnouncementsListComponent = dataList.map((announcement, index) => (
    <div key={announcement.postId}>
      <AsureCloudAnnouncements
        postTitle={announcement.postTitle}
        postDate={announcement.postDate}
        postDetail={announcement.postDetail}
      />
      {index !== dataList.length - 1 ? (
        <p className="announcements-divider m-0" />
      ) : (
        ''
      )}
    </div>
  ));

  const AnnouncementsContent = (
    <div className="announcements-section">
      <h1 className="h5 font-weight-bold">Announcements</h1>
      <div className="shadow-sm m-3 announcements-wrapper">
        {AnnouncementsListComponent}
        <a href="https://www.asuresoftware.com/" className="d-block">View all announcements</a>
      </div>
    </div>
  );

  if (target) {
    return createPortal(AnnouncementsContent, target);
  }
  return null;
}

export default injectIntl(Announcements);
