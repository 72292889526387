import React from 'react';
import './TestComponent.scss';
import { createPortal } from 'react-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

function TestComponent() {
  const target = document.getElementById('test-component-ui');
  // const [target] = useState(document.getElementById('test-component-ui'));
  const componentContent = (
    <div className="test-class" id="test-id">
      <FormattedMessage id="TEST_COMPONENT" />
    </div>
  );

  if (target) {
    return createPortal(componentContent, target);
  }
  return null;
}

export default injectIntl(TestComponent);
